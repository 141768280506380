import React, { useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { Container, Contacts, Grid, GridItem, Link } from 'components/UI';
import NavColumn from './components/NavColumn';
import OfficeCard from './components/OfficeCard';
import Accordion from './components/Accordion';
import Copyright from './components/Copyright';
import TopScroller from './components/TopScroller/TopScroller';
import { cisRegion, usaRegion, mxRegion, arabicRegion, azerRegion } from 'define/locale';
import { useIsSsr } from 'utils/hooks';
import { isRequestedFrom, useTrans, useLang } from 'utils/localisation';
import { useOfficesInfo } from 'staticQueries/offices-info';
import * as styles from './Footer.module.scss';

import {
  industries,
  industriesDe,
  industriesAe,
  industriesAz,
  techStack,
  techStackAe,
  services,
  servicesDe,
  servicesAe,
  servicesAz,
  projects,
  projectsDe,
  projectsAe,
  company,
  companyDe,
  companyAe,
  companyAz,
  offices,
  officesUS,
  officesMena,
  officesAe,
  officesAz,
} from 'define/footer';

function Footer() {
  const officesData = useOfficesInfo();
  const isSsr = useIsSsr();
  const [companyData, setCompanyData] = useState([]);

  const t = useTrans(['common', 'footer']);
  const lang = useLang();

  const officesIds = useMemo(() => {
    if (!isSsr) {
      if (lang === 'de') {
        return offices;
      }
      if (lang === 'ae') {
        return officesAe;
      }
      if (lang === 'az' || isRequestedFrom(azerRegion)) {
        return officesAz;
      }
      if (isRequestedFrom(arabicRegion)) {
        return officesMena;
      }
      if (isRequestedFrom(usaRegion) || isRequestedFrom(mxRegion)) {
        return officesUS;
      }
      return offices;
    }
  }, [isSsr, lang]);

  const officesList = useMemo(() => {
    const result = officesIds?.reduce((acc, locationItem) => {
      const office = officesData.find(office => office.id === locationItem);
      office && acc.push(office);
      return acc;
    }, []);

    return result;
  }, [lang, officesIds, officesData]);

  const getIndustriesList = () => {
    switch (lang) {
      case 'de':
        return industriesDe;
      case 'ae':
        return industriesAe;
      case 'az':
        return industriesAz;
      case 'en':
      default:
        return industries;
    }
  };

  const getServicesList = () => {
    switch (lang) {
      case 'de':
        return servicesDe;
      case 'ae':
        return servicesAe;
      case 'az':
        return servicesAz;
      case 'en':
      default:
        return services;
    }
  };

  const getProjectsList = () => {
    switch (lang) {
      case 'de':
        return projectsDe;
      case 'ae':
      case 'az':
        return projectsAe;
      case 'en':
      default:
        return projects;
    }
  };

  useEffect(() => {
    if (lang === 'ae') {
      setCompanyData(companyAe);
    } else if (lang === 'az') {
      setCompanyData(companyAz);
    } else if (!isRequestedFrom(cisRegion)) {
      const notCISRegion =
        lang === 'de'
          ? companyDe.filter(({ region }) => region !== 'cis')
          : company.filter(({ region }) => region !== 'cis');

      setCompanyData(notCISRegion);
    } else {
      lang === 'de' ? setCompanyData(companyDe) : setCompanyData(company);
    }
  }, []);

  const industriesList = getIndustriesList();
  const servicesList = getServicesList();
  const projectsList = getProjectsList();
  const techStackList = lang === 'ae' || lang === 'az' ? techStackAe : techStack;
  const officesLink =
    lang === 'ae' || lang === 'az'
      ? 'https://andersenlab.com/about-us#offices'
      : '/about-us#offices';

  return (
    <footer className={styles.footer}>
      <Container background="transparent" padding="Vertical">
        <div className={styles.main}>
          <div className={styles.accordionLayout}>
            <Accordion title={t('techStack')}>
              <NavColumn items={techStackList} />
            </Accordion>
            <Accordion title={t('industries')}>
              <NavColumn items={industriesList} />
            </Accordion>
            <Accordion title={t('services')}>
              <NavColumn items={servicesList} />
            </Accordion>
            <Accordion title={t('caseStudies')}>
              <NavColumn items={projectsList} />
            </Accordion>
            <Accordion title={t('company')}>
              <NavColumn items={companyData} />
            </Accordion>
          </div>
          <section className={cn(styles.section, styles.navigation)}>
            <NavColumn title={t('techStack')} items={techStackList} />
            <NavColumn title={t('industries')} items={industriesList} />
            <NavColumn title={t('services')} items={servicesList} />
            <NavColumn title={t('caseStudies')} items={projectsList} />
            <NavColumn title={t('company')} items={companyData} />
          </section>
          <section className={cn(styles.section, styles.offices)}>
            <p className={styles.title}>{t('ourOffices')}</p>
            <div className={styles.sectionContent}>
              <Grid
                gutterX="20px"
                xsm={{ cols: 2 }}
                sm={{ cols: 2 }}
                md={{ cols: 2 }}
                lg={{ cols: 4 }}
                xl={{ cols: 4 }}
              >
                {officesList?.map(({ city, address, phone }) => {
                  return (
                    <GridItem key={city}>
                      <OfficeCard city={city} address={address} phone={phone} />
                    </GridItem>
                  );
                })}
              </Grid>
              <div className={styles.buttonRow}>
                <Link href={officesLink} className={styles.button}>
                  {t('seeMore')}
                </Link>
              </div>
            </div>
          </section>
          <section className={cn(styles.section, styles.contactUs)}>
            <p className={styles.title}>{t('contactUs')}</p>
            <div className={styles.sectionContent}>
              <Contacts className={styles.column} />
              <TopScroller />
            </div>
          </section>
        </div>
      </Container>
      <Copyright />
    </footer>
  );
}

export default Footer;
