import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';

import BurgerBtn from './components/BurgerBtn';
import MobileMenu from './components/MobileMenu';
import MainNav from './components/MainNav';
import LangSwitcher from './components/LangSwitcher/LangSwitcher';
import { Button, Link } from 'components/UI';
import AnchorMenu from './components/AnchorMenu';
import Phone from './components/Phone';
import BookingButton from './components/BookingButton';
import { useHistoryCallback } from 'utils/routing';
import { useDocumentScrollThrottled, useWindowResize } from 'utils/hooks';
import { isRequestedFrom, useLang, useTrans } from 'utils/localisation';
import { useHeaderTranslation } from 'staticQueries/header-translation';
import { arabicRegion, germanRegion, azerRegion } from 'define/locale';

import * as styles from './Header.module.scss';

const whiteBgPages = [
  '/industries/financial-services/digital-banking-app',
  '/industries/healthcare/telemedicine-app-development',
];

const firstLevelPages = [
  '/services',
  '/about-us',
  '/industries',
  '/find-developers',
  '/case-studies',
  '/contact-us',
];

function Header() {
  const lang = useLang();
  const t = useTrans(['header']);
  const { anchors, links } = useHeaderTranslation();
  const currentLink = useLocation();
  const isIndexPage = currentLink.pathname === '/';
  const isWhiteBg =
    currentLink.pathname.includes('/project-cases') || whiteBgPages.includes(currentLink.pathname);
  const isFirstLevel = firstLevelPages.includes(currentLink.pathname);
  const showProgressIndicator = currentLink.pathname.includes('/blueprint');
  const [anchorLinks, setAnchorLinks] = useState(null);
  const [headerFixed, setHeaderFixed] = useState(false);
  const [hideHeader, setHideHeader] = useState(true);
  const [mobileMenuVisible, setActiveMobileMenu] = useState(false);
  const [enableSwitcher, setEnableSwitcher] = useState(false);
  const { width } = useWindowResize();
  const firstLevelTransparent = isFirstLevel && width <= 545;
  const shouldBeTransparent = isIndexPage || firstLevelTransparent;
  const showBooking = !headerFixed && !isFirstLevel && currentLink.pathname !== '/contact-us';
  const contactUsLink =
    lang === 'ae' || lang === 'az' ? 'https://andersenlab.com/contact-us' : '/contact-us';

  const toggleMobileMenu = useCallback(() => {
    setActiveMobileMenu(!mobileMenuVisible);
  }, [mobileMenuVisible]);

  const handlerScroll = useCallback(() => {
    const scrollTop = (window.scrollY || document.scrollTop) - (document.clientTop || 0);

    scrollTop > 20 ? setHeaderFixed(true) : setHeaderFixed(false);
  }, [isFirstLevel]);

  const scrollProgress = useCallback(() => {
    const scrollPx = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    const heightPx = scrollHeight - clientHeight;
    const scrolled = (scrollPx / heightPx) * 100;

    const progress = document.getElementById('progressBar');
    if (progress) {
      progress.style.width = `${scrolled}%`;
    }
  });

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > 80;

    setHideHeader(isScrolledDown && isMinimumScrolled);
  });

  useEffect(() => {
    if (lang === 'ae' || lang === 'az' || lang === 'de' || isRequestedFrom(germanRegion)) {
      setEnableSwitcher(true);
    }
    if (lang === 'en' && isIndexPage) {
      if (isRequestedFrom(arabicRegion) || isRequestedFrom(azerRegion)) {
        setEnableSwitcher(true);
      }
    }
    if (lang === 'en' && !isIndexPage) {
      if (isRequestedFrom(arabicRegion) || isRequestedFrom(azerRegion)) {
        setEnableSwitcher(false);
      }
    }
  }, [lang, isIndexPage]);

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll, { passive: true });
    handlerScroll();
    return () => {
      window.removeEventListener('scroll', handlerScroll);
    };
  }, []);

  useEffect(() => {
    if (showProgressIndicator) {
      window.addEventListener('scroll', scrollProgress);
      scrollProgress();
      return () => {
        window.removeEventListener('scroll', scrollProgress);
      };
    }
  }, []);

  useEffect(() => {
    const redactedCurrentLink = currentLink.pathname.endsWith('/')
      ? currentLink.pathname.slice(0, -1)
      : currentLink.pathname;
    const currentAnchorLinks = anchors?.find(({ url }) => url === redactedCurrentLink);

    if (currentAnchorLinks) {
      setAnchorLinks(currentAnchorLinks.links);
    } else {
      setAnchorLinks(null);
    }
  }, [currentLink.pathname]);

  useHistoryCallback(() => {
    setActiveMobileMenu(false);
  });

  const mobileTheme = useMemo(() => {
    if (
      (!isIndexPage && !firstLevelTransparent) ||
      (shouldBeTransparent && (headerFixed || mobileMenuVisible))
    ) {
      return 'dark';
    }

    return 'default';
  }, [isIndexPage, headerFixed, mobileMenuVisible, firstLevelTransparent]);

  const renderLogo = useCallback(() => {
    if (enableSwitcher) {
      return (
        <>
          {!mobileMenuVisible && (
            <Link href="/" className={styles.logoWrap}>
              <div
                className={cn(
                  styles.logo,
                  !headerFixed && shouldBeTransparent && styles.transparentLogo,
                )}
                aria-label="Andersen logo"
                role="img"
              />
            </Link>
          )}
          {mobileMenuVisible && (
            <LangSwitcher
              isIndexPage={isIndexPage}
              isHeaderFixed={headerFixed}
              mobileMenuVisible={mobileMenuVisible}
            />
          )}
        </>
      );
    }

    return (
      <Link href="/" className={styles.logoWrap}>
        <div
          className={cn(
            styles.logo,
            !headerFixed && shouldBeTransparent && !mobileMenuVisible && styles.transparentLogo,
          )}
          aria-label="Andersen logo"
          role="img"
        />
      </Link>
    );
  }, [mobileMenuVisible, enableSwitcher, headerFixed, isIndexPage, firstLevelTransparent]);

  return (
    <header
      className={cn(
        styles.header,
        headerFixed && styles.fixed,
        shouldBeTransparent && !mobileMenuVisible && styles.transparentBg,
        anchorLinks && headerFixed && hideHeader && styles.scrollTop,
      )}
    >
      <div className={styles.topLine}>
        <div className={styles.topLineLayout}>
          {renderLogo()}
          {lang !== 'ae' && lang !== 'az' && (
            <BookingButton
              className={cn(
                styles.bookingButton,
                styles.mobileHidden,
                !headerFixed && isIndexPage && styles.whiteColor,
              )}
              theme="mobile"
            />
          )}
          <div className={styles.mobilePanel}>
            <BurgerBtn
              fixed={mobileMenuVisible}
              theme={mobileTheme}
              active={mobileMenuVisible}
              onClick={toggleMobileMenu}
            />
            {mobileMenuVisible && <MobileMenu links={links} />}
          </div>
          <nav className={styles.navigation}>
            <MainNav
              items={links}
              mods={['lastItemNoPadding']}
              currentLink={currentLink.pathname}
              isIndexPage={isIndexPage}
              isHeaderFixed={headerFixed}
            />
            <div className={styles.separator} />

            {enableSwitcher && (
              <div className={styles.item}>
                <LangSwitcher
                  isIndexPage={isIndexPage}
                  isHeaderFixed={headerFixed}
                  mobileMenuVisible={mobileMenuVisible}
                />
              </div>
            )}
            <Button
              tag="a"
              size="sm"
              href={contactUsLink}
              className={cn(!headerFixed && isIndexPage ? styles.buttonTransparent : styles.button)}
            >
              {t('contactUsDesktop')}
            </Button>
          </nav>
          {showBooking && (
            <div className={styles.communicationWrapper}>
              {lang === 'ae' || lang === 'az' ? (
                <Phone lang={lang} />
              ) : (
                <BookingButton fontSize="lg" theme={isWhiteBg && 'blackColor'} />
              )}
            </div>
          )}
        </div>
        {anchorLinks && <AnchorMenu anchorLinks={anchorLinks} headerFixed={headerFixed} />}
      </div>
      {showProgressIndicator && (
        <div className={styles.progressContainer}>
          <div className={styles.progressbar} id="progressBar" />
        </div>
      )}
    </header>
  );
}

export default Header;
